
import { Component, Vue } from 'vue-property-decorator'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({
      previewMode: 'previewMode'
    })
  }
})
export default class TemplatePreview extends Vue {
  previewMode!: string
  templateId!: string

  get templateUrl () : string {
    return 'https://u' + this.templateId + '.mono.site/'
  }

  get previewStyle () : string {
    return this.previewMode + '-iframe-container'
  }

  beforeMount (): void {
    this.templateId = this.$route.query.id as string
  }
}
